.header {
  background: url("../../assets/bg-picture@2x.webp") no-repeat;
  background-size: cover;
}

.navContainer {
  position: fixed;
  z-index: 999;
  width: 100%;
}

.nav {
  margin: auto;
  width: 100%;
  max-width: 144rem;
}

.navFix {
  background-color:#212121;
}

@media screen and (min-width: 768px) {
  .header {
    background: url("../../assets/bg-dt-picture@2x.webp") no-repeat;
    background-size: cover;
  }
}
