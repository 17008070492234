.notFound {
  background: url("../../../assets/bg-404@2x.webp") no-repeat;
  background-size: cover;
  padding: 4rem 1.2rem 3.2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
}

.backLink {
  width: 100%;
  padding: 1.5rem 0;
  background-color: #212121;
  color: #fafafa;
  text-align: center;
  text-decoration: none;
  border-radius: 26px;
  margin: 0 auto 5vh;
  max-width: 37.5rem;
  font-size: 1.8rem;
}

.text {
  line-height: 1.5;
  margin-bottom: 5vh;
}

.heading {
  margin-bottom: 1.6rem;
  font-size: 2.8rem;
}

.pattern {
  position: absolute;
  top: 4vh;
  left: 50%;
  transform: translate(-50%);
  width: 10rem;
}

.content {
  text-align: center;
}

@media screen and (min-width: 375px) {
  .pattern {
    position: absolute;
    top: 8vh;
    left: 50%;
    transform: translate(-50%);
    width: 15.8rem;
  }

  .backLink {
    margin: 0 auto 9vh;
  }
}

@media screen and (min-width: 768px) {
  .backLink {
    margin: 0 auto 22vh;
  }

  .notFound {
    padding: 4rem 7.2rem 2.4rem;
  }

  .text {
    margin-bottom: 3.2rem;
  }

  .pattern {
    position: absolute;
    top: 10vh;
    width: 27.5rem;
  }
}

@media screen and (min-width: 820px) {
  .pattern {
    top: 20vh;
  }
}

@media screen and (min-width: 1024px) {
  .notFound {
    background: url("../../../assets/bg-dt-404@2x.webp") no-repeat;
    background-size: cover;
    justify-content: center;
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 9rem;
  }
  .pattern {
    width: 20rem;
    position: unset;
    transform: translate(0);
  }

  .content {
    text-align: left;
  }

  .backLink {
    margin: 0;
    margin-right: auto;
  }

  .copyright {
    position: absolute;
    bottom: 1.6rem;
    left: 50%;
    transform: translate(-50%);
  }
}

@media screen and (min-width: 1440px) {
  .heading {
    font-size: 4.4rem;
  }

  .pattern {
    width: 34.5rem;
    text-align: right;
  }

  .text {
    width: 75%;
  }
}
