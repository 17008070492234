.video {
  padding-top: 6rem;
  padding-bottom: 8rem;
  max-width: 144rem;
  margin: auto;
  position: relative;
}

.heading {
  text-transform: uppercase;
  font-size: 2.3rem;
  margin-bottom: 2.4rem;
}

.imageContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
}

.imageContainer::after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.playIcon {
  position: absolute;
  z-index: 99;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 44px;
  height: 44px;
  background-color: var(--limeGreen);
  display: grid;
  place-content: center;
  border-radius: 100%;

}

.playIcon:hover {
  cursor: pointer;
}

.playIcon:hover::before {
  transform: translate(-50%, -50%) scale(1.1);
  transition: all 0.2s ease;
}

.playIcon img {
  position: relative;
  z-index: 100;
}

.playIcon::before {
  content: "";
  width: 60px;
  height: 60px;
  display: block;
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(205, 234, 80, 0.5);
  transition: all 0.2s ease;
  border-radius: 100%;
}

.text {
  line-height: 1.5;
}

.videoWrapper {
  position: relative;
  margin-bottom: 3.2rem;
  aspect-ratio: 1.71;
}

.videoPlayer {
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (min-width: 1024px) {
  .video {
    display: flex;
    gap: 2.2rem;
  }

  .heading {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    text-align: end;
    line-height: 1.25;
    font-size: 3rem;
  }

  .headingFix {
    display: block;
  }
}

@media screen and (min-width: 1440px) {
  .video {
    gap: 5.2rem;
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
  .mainContent {
    max-width: 85.6rem;
    margin: auto;
    position: relative;
  }

  .mainContent::after {
    content: "";
    display: block;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    right: -40px;
    background: url("../../../assets/pattern-detial.svg") no-repeat;
  }

  .headingContainer {
    position: absolute;
    left: 17rem;
  }
}
