:root {
  --bodyBackground: #fff;
  --headingsColor: #1c1c1c;
  --textColor: #323232;
  --limeGreen: #cdea50;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Azeret Mono", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bodyBackground);
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}

h1,
h2 {
  font-weight: 600;
  color: var(--headingsColor);
}

p {
  font-weight: 400;
  color: var(--textColor);
  font-size: 1.4rem;
}

li {
  list-style: none;
}

.container {
  max-width: 144rem;
  margin: auto;
  position: relative;
}

.sitePadding {
  padding: 0 1.2rem;
}

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.siteMainNav__fix {
  background-color: #212121;
}

.siteMainNav__fix > nav > div {
  padding-top:1.5rem;
  padding-bottom: 1.5rem;
}

.isActive {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-name: slideFromBottom;
}

.overflowHidden {
  overflow: hidden;
}

@keyframes slideFromBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
    transition: opacity .8s cubic-bezier(.77,0,.175,1),transform .8s cubic-bezier(.77,0,.175,1);
  }
}

@media screen and (min-width: 768px) {
  .sitePadding {
    padding: 0 7.2rem;
  }
}

@media screen and (min-width: 768px) {
  .sitePadding {
    padding: 0 7.2rem;
  }
}

@media screen and (min-width: 1440px) {
  .container {
    overflow-y: clip;
  }
}
