.mobileMenu {
    width: 100vw;
    height: 100vh;
    padding: 2.4rem 1.2rem;
    position: fixed;
    top: 0;
    z-index: 99;
    background-color: #212121;
    background-image: url('../../../assets/pattern-mobil-menu.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom;
    transform: translateX(100%);
}

.closeContainer {
    text-align: right;
    margin-bottom: 23vw
}

@media screen and (min-width: 768px) {
    .mobileMenu {
        background-size: 60vw;
        background-position-x: 100%;
        padding: 3.2rem 7.2rem;
    }
    
}