.highlights {
  padding-bottom: 6rem;
  padding-top: 6rem;
  position: relative;
}

.section {
  background-color: #cdcfd0;
}

.container {
  max-width: 144rem;
  margin: auto;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 5.2rem;
  margin-bottom: 5.2rem;
}

.heading,
.textHeading {
  text-transform: uppercase;
  font-size: 2.3rem;
  margin-bottom: 2.4rem;
  line-height: 1.25;
}

.highlight {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}

.textHeading {
  margin-bottom: 1.6rem;
}

.text {
  line-height: 1.5;
}

.link {
  background-color: #323232;
  width: fit-content;
  padding: 1.6rem 3.2rem;
  border-radius: 2.6rem;
  border: 1px solid transparent;
}

.linkText {
  color: #fafafa;
  font-size: 1.8rem;
  font-weight: 600;
}

.link:hover, .link:focus, .link:active {
  cursor: pointer;
  background-color: inherit;
  border: 1px solid #323232;
  border-radius: 2.6rem;
}

.link:hover > .linkText, .link:focus > .linkText, .link:active > .linkText {
  color: #323232;
}

.leftWrapper,
.linkDesktop {
  display: none;
}

@media screen and (min-width: 1024px) {
  .sectionWrapper {
    padding-top: 14rem;
    display: flex;
    max-width: 144rem;
    margin: auto;
  }

  .animatedContent {
    position: relative;
    margin-top: 10vw;
  }

  .sectionWrapper > div {
    width: 50%;
  }

  .highlights {
    padding-bottom: 0;
    padding-top: 0;
  }

  .text {
    padding-right: 2rem;
  }

  .highlight {
    flex-direction: row-reverse;
    align-items: flex-end;
  }

  .imageContainer {
    width: 100%;
    max-width: 63.6rem;
  }

  .textContainer {
    display: none;
  }

  .textContainerDT {
    display: block;
    position: absolute;
    opacity: 0;
  }

  .wrapper {
    gap: 2.4rem;
  }
  .description {
    position: sticky;
    top: 100px;
    height: 40vw;
  }
  .heading {
    font-size: 3rem;
    width: 75%;
  }

  .headerMobile {
    display: none;
  }

  .leftWrapper {
    display: block;
    position: relative;
  }

  .linkDesktop {
    display: block;
    pointer-events: all;
  }

  .linkMobile {
    display: none;
  }
}

@media screen and (min-width: 1440px) {

  .textContainer {
    padding-right: 13rem;
  }
  .animatedContent {
    margin-top: 25rem;
  }

  .description {
    height: 59rem;
  }
}
