.text {
    line-height: 1.5;
    margin-bottom: 1.6rem;
}

.heading {
    font-size: 3.5rem;
    margin-bottom: 3.2rem;
}

.section {
    padding-top: 12rem;
    max-width: 144rem;
    margin: auto;
}
