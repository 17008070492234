.mobileContainer {
  padding-top: 2.9rem;
  padding-bottom: 2.9rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobileMenuSlideIn {
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-name: slideToLeft;
}

@keyframes slideToLeft {
  from {
    transform: translateX(100%);
    transition: all 0.5s ease-in;
  }

  to {
    transform: translateX(0);
    transition: all 0.5s ease-in;
  }
}

.mobileMenuSlideOut {
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-name: slideToRight;
}

@keyframes slideToRight {
  from {
    transform: translateX(0);
    transition: all 0.5s ease-in;
  }

  to {
    transform: translateX(100%);
    transition: all 0.5s ease-in;
  }
}

@media screen and (min-width:768px) {
  .mobileContainer {
    padding-top: 2.1rem;
    padding-bottom: 2.1rem;
  }
}