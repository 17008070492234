.hero {
  padding-top: 5rem;
  padding-bottom: 9.5rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  position: relative;
  overflow: hidden;
}

.imageContainer {
  position: absolute;
  width: calc(100vw - 2.4rem);
  max-width: 37.5rem;
  top: 6rem;
}

.pattern {
  position: absolute;
  z-index: 9;
  right: 0;
  top: 35%;
  width: 35%;
}

.heading {
  color: #fafafa;
  text-transform: uppercase;
  font-size: 2.8rem;
  line-height: 1.25;
  margin-bottom: 4rem;
  position: relative;
  z-index: 99;
}

.link {
  background-color: var(--limeGreen);
  display: inline-block;
  padding: 1.6rem 3.2rem;
  border-radius: 26px;
  font-size: 1.8rem;
  font-weight: 600;
  color: #1c1c1c;
  position: relative;
  z-index: 9;
  border: 1px solid transparent;
}

.wordFix {
  display: block;
}

.link:hover, .link:focus, .link:hover, .link:active {
  cursor: pointer;
  background-color: #1c1c1c;
  color: var(--limeGreen);
  border: 1px solid var(--limeGreen);
  border-radius: 26px;
}

@media screen and (min-width: 768px) {
  .imageContainer {
    position: absolute;
    max-width: 80rem;
    top: unset;
    bottom: 0;
    right: -10rem;
    width: 100%;
    left: unset;
    transform: translateX(0);
  }

  .heading {
    font-size: 3.5rem;
    max-width: 70rem;
  }
  .wordFix {
    display: inline;
  }

  .pattern {
    top: -5rem;
    right: 17.5rem;
    width: 21rem;
  }
}

@media screen and (min-width: 1024px) {
  .pattern {
    top: 35rem;
  }
}

@media screen and (min-width: 1440px) {
.hero {
  max-width: 144rem;
  margin: auto;
}

.imageContainer {
  right: calc(100vw - 1540px);
}
}

@media screen and (min-width: 1540px) {

  .imageContainer {
    right: 0;
  }
  }
  